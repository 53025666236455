<template>
    <b-modal size="lg"
             centered
             hide-footer
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <template v-slot:modal-title>
            <strong v-if="!category">
                Create Category "{{ type.charAt(0).toUpperCase() + type.slice(1) }}"
            </strong>
            <strong v-else>
                Update Category "{{ type.charAt(0).toUpperCase() + type.slice(1) }}"
            </strong>
        </template>

        <b-form v-on:submit.prevent="submit"
                v-on:reset="reset">
            <div class="form-row">
                <b-form-group class="col-12"
                              label="Name"
                              label-for="name">
                    <b-form-input id="name"
                                  name="name"
                                  type="text"
                                  required
                                  v-model="name">
                    </b-form-input>
                </b-form-group>

                <b-form-group class="col-12"
                              label="Color"
                              label-for="color">
                    <v-swatches id="color"
                                inline
                                v-model="color">
                    </v-swatches>
                </b-form-group>
            </div>

            <div class="d-flex justify-content-between">
                <button type="reset"
                        class="btn btn-secondary"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    Reset
                </button>
                <button type="submit"
                        class="btn btn-primary"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    Save
                </button>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import { BModal, BForm, BFormGroup, BFormInput, } from "bootstrap-vue";

import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

export default {
    name: "ModalCategory",
    components: {
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        VSwatches,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
        "type": {
            type: String,
            enum: ["event", "promotion"],
            default: "event",
        },
        "category": {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,

            name: "",
            color: null,
        };
    },
    computed: {
        allowSave () {
            return this.name && this.color;
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },

        async submit () {
            if (!this.allowSave) {
                return;
            }

            this.isLoading = true;
            try {
                await this.$store.dispatch(
                    "category/addCategory",
                    {
                        type: this.type,
                        name: this.name,
                        color: this.color,
                    }
                );
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.$emit("input", false);
            this.reset();
        },

        reset () {
            this.name = "";
            this.color = "";
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
